@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap");
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
p {
  margin: 0;
}
html,
body {
  height: 100%;
  font-family: "Lato", sans-serif;
  background: #f0f3f4;
  &::-webkit-scrollbar {
    width: 4px;
    height: 10px;
  }
  &::-webkit-scrollbar-button {
    background: #eee;
  }
  // &::-webkit-scrollbar-track-piece {
  //   background: #888;
  // }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
  }
}
.hot-toast {
  min-width: 250px !important;
  margin-top: 20% !important;
  .hot-toast-icon {
    align-self: unset !important;
    padding: unset !important;
    margin-left: 16px;
    .hot-toast-loader-icon {
      width: 24px;
      height: 24px;
    }
  }
  .hot-toast-message {
    font-size: 20px;
  }
}

.cursor-pointer {
  cursor: pointer;
}
